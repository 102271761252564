












import Vue from "vue";
import store from "../../store/shop";
import { Env } from '../../common/util/env';
import * as SettingIndex from "../../model/shop/shop/settingIndex";
import { AxiosResponse } from "axios";

export default Vue.component("Loading", {
  mounted() {
    store
      .dispatch("setApiToken", this.token)
      .then(() => {
        this.fetchShopIotSetting();
      });
    store
      .dispatch("fetchShopSetting")
      .then(() => {
        this.redirectToHome();
      })
      .catch((error: Error) => {
        console.error(error);
        this.redirectToLogout();
      });
  },
  computed: {
    token(): string {
      return this.$route.query.token;
    }
  },
  methods: {
    redirectToHome() {
      store.commit('save');

      if (store.getters.canUseEatIn) {
        this.$router.push(`/${this.$route.params.prefix}/${this.$route.params.shoppix}/home`);
      } else if(store.getters.canUseTakeout) {
        this.$router.push(`/${this.$route.params.prefix}/${this.$route.params.shoppix}/takeout`);
      }
    },
    redirectToLogout() {
        const shoppix = this.$route.params.shoppix;
        const loginUrl = `${Env.HOST_ADMIN}/c/${shoppix}/login`;
        location.href = loginUrl;
    },
    fetchShopIotSetting() {
      store
        .dispatch("fetchShopIotSetting")
        .then((response: AxiosResponse<SettingIndex.response>) => {
          if (response.data.status !== "success") {
            throw new Error();
          }
          return;
        });
    },

  },
});
